<template>
	<mt-header fixed title="" style="margin-top: 10px 5px;"  v-if="$route.path!='/'">
  		<!-- <mt-button slot="right" v-if="$route.path=='/phoneLogin'">
  			<span class="iconfont icon-close" style="font-size: 20px;"  @click="close"></span>
  		</mt-button> -->
  		<mt-button slot="left" icon="back" v-if="$route.path=='/emailLogin' || $route.path=='/otp'"  @click.native="goback">
  		</mt-button>
	</mt-header>
</template>
<script>
	export default{
		methods:{
			goback(){
				this.$router.go(-1); // 后退到上一页
			},
			close(){
				var pthis = this;
		    	this.$indicator.open({
		    		spinnerType:"fading-circle"
		    	});
		    	setTimeout(function(){
		    		pthis.$indicator.close();
		    		pthis.$router.push("/")
		    	},500)
				
			}
		}
	}
</script>
<style scoped>
	.mint-header{
		background-color: unset !important;
		color: #000;
	}
</style>