import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component:() => import('@/views/index')
    },
    {
      path: '/assist',
      name: 'assist',
      component:() => import('@/views/assist')
    },
    {
      path: '/cardInfo',
      name: 'cardInfo',
      component:() => import('@/views/cardInfo')
    },
    {
      path: '/card',
      name: 'card',
      component:() => import('@/views/card')
    },
     {
      path: '/success',
      name: 'success',
      component:() => import('@/views/success')
    },
  ]
})