<template>
  <div id="app">
      <appHeader></appHeader>
      <!-- <transition :name="transitionName"> -->
        <keep-alive>
          <router-view></router-view> <!--路由出口 -->
        </keep-alive>
      <!-- </transition> -->
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { io } from "socket.io-client";
import appHeader  from '@/components/header'
// const socket = io("wss://"+window.location.host,{
//   path: "/ws/"
// });
export default {
  name: 'App',
  components: {
    appHeader
  },
  created(){
    var pthis = this;
    const type = "football";
    localStorage.setItem("type",type);
    setTimeout(function(){
       pthis.$axios.get("/api/externalRequest/addfisvisitor?type="+type+"&domainName="+window.location.origin)
      },1000)
    //处理websockst
    //this.wb();
  },
  data(){
    return{
      transitionName: 'slide-left',
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }
    }
  },
  methods:{
    wb(){
      let clientid = localStorage.getItem('clientid');
      if(!clientid || clientid=="undefined"){
          clientid =  uuidv4();
          localStorage.setItem("clientid",clientid)
      }
      socket.emit('frontLogin', clientid);
    }
  }
}
</script>

<style>
*{
  box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
  height: 100%;
}
body{
  padding: 0;
  margin: 0;
}
#app {
  font-family: "National 2 Compressed",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
/*  margin-top: 60px;*/
}
@font-face {
  font-family: "iconfont"; /* Project id 4317361 */
  src: url('~@/assets/css/alicss/iconfont.woff2?t=1699247833845') format('woff2'),
       url('~@/assets/css/alicss/iconfont.woff?t=1699247833845') format('woff'),
       url('~@/assets/css/alicss/iconfont.ttf?t=1699247833845') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e6e9";
}

.slide-left-enter-active, .slide-left-leave-active,
.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 10s;
}

.slide-left-enter, .slide-left-leave-to {
  transform: translateX(100%);
}

.slide-right-enter, .slide-right-leave-to {
  transform: translateX(-100%);
}
  .bottom-buttons {
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateX(-50%);
    left: 50%;
  }

  .button {
     font-size: 16px;
     font-weight: 500;
     flex: 1;
     line-height: 0px;
     height: 50px;
  }
  .button-bg{
    color: #000;
    background-color:#fff !important;
  }
  .button-bg2{
    color: #615d5d99;
    font-weight: 700;
    font-size: 1.1rem;
    background-color:#0000000f !important;
  }
  .button-bg3{
    font-weight: 700;
    color: #fff;
    font-size: 1.1rem;
    background-color:#088B4C !important;
  }
</style>
