import Vue from 'vue'
import router from './router'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import App from './App.vue'
import axios from 'axios'
import qs from 'qs'

Vue.use(MintUI)
Vue.axios = Vue.prototype.$axios = axios
Vue.qs = Vue.prototype.$qs = qs
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
